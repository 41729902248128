import { initModa } from "./modal";


let newsel = document.getElementById('news')

let template = 
`<li class="articles__item publication">
<img class="publication__img" src="{{imglink}}?transform=h_235,w_354,c_fill" alt="Фото.">
<div class="publication__content">
  <h3 class="publication__title">{{title}}</h3>
  <p class="publication__text">{{text}}</p>
  <button class="publication__btn btn" id="btn-publication-{{id}}">Продолжение статьи в&nbsp;приложении</button>
</div>
</li>`

function loadNews(id){
    let url = `https://api.orujeinik.online/api/weapons/${id}`;
    fetch(url)
    .then(function(res) {
        return res.json()
    }).then(data=>{
        console.log(data)
        let a={
            imglink: data.weapon.cover.meta.secure_url,
            title: data.weapon.title,
        }
        let simpleText = data.weapon.rows.find((o)=>{
            if(o.type==='simpleText') return true
            return false
        })
        a.text = simpleText.content

        const rendered = Mustache.render(template, a);
        document.getElementById('news').innerHTML += rendered;

    })
    .catch(function() {

    });
}

// loadNews(810);

// loadNews(809);

// loadNews(808);


function articles(){
    let url = `https://cp.orujeinik.online/api/articles?page=1&limit=3&q=&is_show=&is_rejected=false&is_updated_after_rejected=false`;
    fetch(url)
    .then(function(res) {
        return res.json()
    }).then(data=>{
        console.log(data)
        data.data.forEach(article=>{

            let a={
                imglink: article.cover.meta.secure_url,
                title: article.title,
                text: article.description,
                id: article.id
             }
     
             const rendered = Mustache.render(template, a);
             document.getElementById('news').innerHTML += rendered;
        })
        data.data.forEach(article=>{
            initModa('modal',  `btn-publication-${article.id}`)
        })

    })
    .catch(function() {

    });
}


articles();



// new Promise((resove, reject)=>{
//     resove(data)
//     reject(error)
// }).then(data=>{

// })